
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body{
    user-select: none;
    text-decoration: none;
    background-color: rgb(243, 212, 183);
    width: 100vw;
    height: 100vh;
}

#root{
    width: 100%;
    height: 100%;
    
}

#root .modal-get{
    
    min-height: 400px;
    
    
    width: 90%;
    max-width: 1200px;
    background-color: white;

    
    margin-left: auto;
    margin-right: auto;
    
    
    
    border-radius: 15px;
    position: relative;
    
    overflow: hidden;
}

.header{
    padding: 14px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 50px;
    font-size: larger;
    color: rgb(51, 51, 51, 0.7);
    border-width: 0;
    border-bottom-width: 1px;
    border-color: rgb(51, 51, 51, 0.3);
    border-style: solid;
}

.header .close-button{
    position: absolute;
    right: 3%;
    top: 15%;
    
}

.header .home-button{
    position: absolute;
    left: 3%;
    top: 15%;
    cursor: pointer;
    color: #555;
}

.header .home-button:hover{
    color: burlywood;
}

.main{
    width: 100%;
    padding: 25px;
    border-width: 0;
    border-bottom-width: 1px;
    border-color: rgb(51, 51, 51, 0.1);
    border-style: solid;
    padding-bottom: 25px;
    overflow: auto;
    position: relative;
}

.netler-input{
    font-size: 0.65rem;
    font-weight: normal;
    color: rgb(51, 51, 51, 0.8);
}

.explain{
    padding: 25px;
    color: rgb(51, 51, 51);
    
}

.explain span{
    font-size: large;
    color: rgb(51, 51, 51, 0.8);
}

.explain .detail{
    font-size: smaller;
    
}

.sonuc-button{
    border: none;
    color: rgb(51, 51, 51, 0.8);
    font-weight: 600;
    height: 50px;
}

.loader{
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
}

.page-link-s{
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 10px;
    padding: 0;
    margin: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(51, 51, 51, 0.5);
    color: rgb(51, 51, 51, 0.8);
    text-decoration: none;
    overflow: hidden;
    
}

.page-link{
    font-size: 10px;
    padding: 4px;
    color: rgb(51, 51, 51, 0.8);
    
    
}


.active > .page-link-s{
    color: rgb(51, 51, 51);
    background-color: rgb(243, 212, 183);
}

.active{
    
    border-radius: 50%;
    color: white;
    
}

.page-item{
    display: flex;
    align-items: center;
}

.sonuc-listesi{
    width: 100%;
    display: flex;
    flex-direction: column;
    
    
    

}

.sonuc-listesi span{
    font-size: larger;
    color: rgb(51, 51, 51, 0.7);
}

.sonuc-listesi thead{
    font-size: x-small;
}

.sonuc-listesi tbody{
    font-size: x-small;
    

}

.sonuc-listesi tbody tr th {
    font-weight: 500;
}


.cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 120px; 
    height: 1.2em; 
    white-space: nowrap;
}

.warning-text{
    width: 100%;
    color: white;
    background-color: rgba(231, 76, 60, 0.75);
    border-radius: 3px;
    padding: 5px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    
}

.warning-text span{
    margin-left: 2px;
    
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.MuiSvgIcon-root-drp-active .MuiStepIcon-root{
    color: #f39c12 !important;
}